import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames/bind';
import { useRef, memo, useEffect } from 'react';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import mainFeaturesCarousel from '../../../../data/homepage/mainFeaturesCarousel';

import useScroll from '../../../../hooks/useScroll';
import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const CarouselNavigator = ({ activeTileIndex, changeTab, mainWidth }) => {
  const leftArrow = useRef();
  const rightArrow = useRef();
  const scrollableContainer = useRef();
  const { position, onScrollHandler } = useScroll();
  const { left, right } = position;

  const spaceBetween = mainWidth < 767 ? 0 : 10;
  const offset = mainWidth < 767 ? 230 : 300;

  useEffect(() => {
    let width = 0;
    for (let i = 0; i < activeTileIndex; i += 1) {
      width += scrollableContainer.current.children[i].offsetWidth + spaceBetween;
    }

    const offset = width + scrollableContainer.current.children[activeTileIndex].offsetWidth
      - (scrollableContainer.current.offsetWidth * 0.68);
    scrollableContainer.current.scrollTo(offset, 0, 'smooth');
  }, [activeTileIndex]);

  const onLeftArrowClick = () => {
    scrollableContainer.current.scrollBy(-offset, 0, 'smooth');
  };

  const onRightArrowClick = () => {
    scrollableContainer.current.scrollBy(offset, 0, 'smooth');
  };

  const leftArrowVisible = (mainWidth < 1110 && left === 'block') ? 'flex' : 'none';
  const rightArrowVisible = (mainWidth < 1110 && right === 'block') ? 'flex' : 'none';

  return (
    <div className={cx('carousel_tabs')}>
      <div
        className={cx('carousel_tabs_arrow', 'carousel_tabs_arrow_left')}
        style={{ display: leftArrowVisible }}
        ref={leftArrow}
        onClick={onLeftArrowClick}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </div>
      <div className={cx('carousel_tabs_container')} onScroll={onScrollHandler} ref={scrollableContainer}>
        {mainFeaturesCarousel.map((mainFeature, index) => (
          <div
            key={mainFeature.tabName}
            className={cx('carousel_tabs_item', {
              active: activeTileIndex === index,
              first: index === 0,
            })}
            onClick={() => changeTab(mainFeature.id)}
          >
            {mainFeature.tabName}
          </div>
        ))}
      </div>
      <div
        className={cx('carousel_tabs_arrow', 'carousel_tabs_arrow_right')}
        ref={rightArrow}
        style={{ display: rightArrowVisible }}
        onClick={onRightArrowClick}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </div>
  );
};
export default memo(CarouselNavigator);

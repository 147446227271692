import { CRYPTOCURRENCIES, PUBLIC_COMPANIES } from '../directory/topic_classes/topicClassTypes';

export const storyPeriodFilters = [
  {
    value: '1d',
    text: '1D',
    tooltip: '1 day',
    active: false,
  },
  {
    value: '1w',
    text: '1W',
    tooltip: '1 week',
    active: true,
  },
  {
    value: '1m',
    text: '1M',
    tooltip: '1 month',
    active: false,
  },
];

export const contentForYouPriceChartFilters = [
  {
    value: '1d',
    text: '1D',
    active: true,
  },
  {
    value: '1w',
    text: '1W',
    active: false,
  },
  {
    value: '1m',
    text: '1M',
    active: false,
  },
];

export const trendingTopicsFilter = [
  {
    value: '15m',
    text: '15M',
    active: false,
    tooltip: '15 minute',
  },
  {
    value: '1h',
    text: '1H',
    active: true,
    tooltip: '1 hour',
  },
  {
    value: '1d',
    text: '1D',
    active: false,
    tooltip: '1 day',
  },
];

export const filingTabs = [
  {
    id: '',
    countryCode: undefined,
    content: 'All',
    active: true,
  },
  {
    id: 'sec-us',
    countryCode: 'US',
    content: 'SEC, US',
    active: false,
  },
  {
    id: 'gazette-gb',
    countryCode: 'GB',
    content: 'The Gazette, GB',
    active: false,
  },
  {
    id: 'companieshouse-gb',
    countryCode: 'GB',
    content: 'Companies House, GB',
    active: false,
  },
];

export const gainersLosersTopics = [
  {
    id: PUBLIC_COMPANIES,
    content: 'Stocks',
    active: true,
  },
  {
    id: CRYPTOCURRENCIES,
    content: 'Crypto',
    active: false,
  },
];

export const gainersLosersCaps = [
  {
    id: 'all',
    content: 'ALL',
    active: false,
  },
  {
    id: 'mega',
    content: 'Mega Cap',
    active: true,
  },
  {
    id: 'large',
    content: 'Large Cap',
    active: false,
  },
  {
    id: 'mid',
    content: 'Mid Cap',
    active: false,
  },
  {
    id: 'small',
    content: 'Small Cap',
    active: false,
  },
  {
    id: 'micro',
    content: 'Micro Cap',
    active: false,
  },
  {
    id: 'nano',
    content: 'Nano Cap',
    active: false,
  },
];

export const newsOrder = [
  {
    id: 'top',
    content: 'Top',
    active: true,
  },
  {
    id: 'latest',
    content: 'Latest',
    active: false,
  },
];

export const sentimentOptions = [
  {
    id: 'all',
    content: 'All',
    active: true,
  },
  {
    id: 'very_positive',
    content: 'Very Positive',
    active: false,
  },
  {
    id: 'positive',
    content: 'Positive',
    active: false,
  },
  {
    id: 'neutral',
    content: 'Neutral',
    active: false,
  },
  {
    id: 'negative',
    content: 'Negative',
    active: false,
  },
  {
    id: 'very_negative',
    content: 'Very Negative',
    active: false,
  },
];

import sentiments from '../../assets/homepage/main_feature_carousel/sentiments.png';
import sentimentsMob from '../../assets/homepage/main_feature_carousel/sentiments-mob.png';
import dcsc from '../../assets/homepage/main_feature_carousel/dcsc.png';
import dcscMob from '../../assets/homepage/main_feature_carousel/dcsc-mob.png';
import pushNotifications from '../../assets/homepage/main_feature_carousel/push-notifications.png';
import pushNotificationsMob from '../../assets/homepage/main_feature_carousel/push-notifications-mob.png';
import internalContent from '../../assets/homepage/main_feature_carousel/internal-content.png';
import internalContentMob from '../../assets/homepage/main_feature_carousel/internal-content-mob.png';
import keyStories from '../../assets/homepage/main_feature_carousel/key-stories.png';
import keyStoriesMob from '../../assets/homepage/main_feature_carousel/key-stories-mob.png';
import allTool from '../../assets/homepage/main_feature_carousel/all-tool.png';
import allToolMob from '../../assets/homepage/main_feature_carousel/all-tool-mob.png';
import trendingContent from '../../assets/homepage/main_feature_carousel/trending-content.png';
import trendingContentMob from '../../assets/homepage/main_feature_carousel/trending-content-mob.png';
import internationalStories from '../../assets/homepage/main_feature_carousel/international-stories.png';
import internationalStoriesMob from '../../assets/homepage/main_feature_carousel/international-stories-mob.png';
import leftWave from '../../assets/homepage/main_feature_carousel/background-wave-left.svg';
import rightWave from '../../assets/homepage/main_feature_carousel/background-wave-right.svg';

export default [
  {
    tabName: 'Sentiment',
    id: 'sentiment',
    title: 'Insight into the mood of the market',
    subTitle: 'AI scores individual news and social media content as positive, negative, or neutral sentiment and aggregates scores.',
    link: 'https://www.cityfalcon.ai/features/sentiment',
    backgroundImage: rightWave,
    backgroundImageTab: rightWave,
    backgroundImageMob: leftWave,
    contentImage: sentiments,
    contentImageMob: sentimentsMob,
    class: 'sentiment',
    styles: {
      marginTop: '133px',
      marginRight: '48px',
      width: '500px',
      height: '235px',
      bgWidth: '1060px',
      bgHeight: '237px',
    },
    tabStyles: {
      marginTop: '83px',
      marginRight: '31px',
      width: '315px',
      height: '148px',
      bgWidth: '840px',
      bgHeight: '190px',
    },
    mobStyles: {
      marginTop: '12px',
      marginRight: '10px',
      width: '239px',
      height: '112px',
      bgWidth: '727px',
      bgHeight: '149px',
    },
    bigScreenStyles: {
      marginTop: '266px',
      marginRight: '96px',
      width: '1000px',
      height: '470px',
      bgWidth: '2649px',
      bgHeight: '592px',
    },
  },
  {
    tabName: 'DCSC Sectors',
    id: 'dcsc',
    title: 'Dynamic Company Sector Classification',
    subTitle: 'AI-driven, dynamic sector classification for the 21st century',
    link: 'https://www.dcsc.ai/',
    backgroundImage: rightWave,
    backgroundImageTab: leftWave,
    backgroundImageMob: leftWave,
    contentImage: dcsc,
    contentImageMob: dcscMob,
    class: 'dcsc',
    styles: {
      marginTop: '36px',
      marginRight: '108px',
      width: '343px',
      height: '427px',
      bgWidth: '1060px',
      bgHeight: '237px',
    },
    tabStyles: {
      marginTop: '23px',
      marginRight: '68px',
      width: '216px',
      height: '269px',
      bgWidth: '840px',
      bgHeight: '190px',
    },
    mobStyles: {
      marginTop: '4px',
      marginRight: '20px',
      marginBottom: '24px',
      width: '88px',
      height: '110px',
      bgWidth: '727px',
      bgHeight: '149px',
    },
    bigScreenStyles: {
      marginTop: '72px',
      marginRight: '216px',
      width: '686px',
      height: '854px',
      bgWidth: '2649px',
      bgHeight: '592px',
    },
  },
  {
    tabName: 'Push Notifications',
    id: 'push-notifications',
    title: 'AI-Driven, Personalised Push Notifications',
    subTitle: 'AI and Big Data curate millions of stories to show you the most relevant',
    link: 'https://www.cityfalcon.ai/features/push-notifications',
    backgroundImage: leftWave,
    backgroundImageTab: leftWave,
    backgroundImageMob: leftWave,
    contentImage: pushNotifications,
    contentImageMob: pushNotificationsMob,
    class: 'push_notifications',
    styles: {
      marginTop: '101px',
      marginRight: '55px',
      width: '462px',
      height: '283px',
      bgWidth: '1060px',
      bgHeight: '237px',
    },
    tabStyles: {
      marginTop: '64px',
      marginRight: '35px',
      width: '291px',
      height: '178px',
      bgWidth: '840px',
      bgHeight: '190px',
    },
    mobStyles: {
      marginTop: '12px',
      marginRight: '10px',
      width: '230px',
      height: '140px',
      bgWidth: '727px',
      bgHeight: '149px',
    },
    bigScreenStyles: {
      marginTop: '202px',
      marginRight: '110px',
      width: '924px',
      height: '566px',
      bgWidth: '2649px',
      bgHeight: '592px',
    },
  },
  {
    tabName: 'Internal Content',
    id: 'internal-content',
    title: 'Unlock insights in your own data',
    subTitle: 'AI and automation help you index, curate, and understand your organisation’s emails, PDFs, and scanned documents.',
    link: 'https://www.cityfalcon.ai/products/extract-and-structure-insights',
    backgroundImage: rightWave,
    backgroundImageTab: leftWave,
    backgroundImageMob: leftWave,
    contentImage: internalContent,
    contentImageMob: internalContentMob,
    class: 'internal_content',
    styles: {
      marginTop: '133px',
      marginRight: '26px',
      width: '500px',
      height: '235px',
      bgWidth: '1060px',
      bgHeight: '237px',
    },
    tabStyles: {
      marginTop: '83px',
      marginRight: '15px',
      width: '315px',
      height: '148px',
      bgWidth: '840px',
      bgHeight: '190px',
    },
    mobStyles: {
      marginTop: '12px',
      marginRight: '9px',
      width: '239px',
      height: '112px',
      bgWidth: '727px',
      bgHeight: '149px',
    },
    bigScreenStyles: {
      marginTop: '266px',
      marginRight: '130px',
      width: '1000px',
      height: '470px',
      bgWidth: '2649px',
      bgHeight: '592px',
    },
  },
  {
    tabName: 'Key Stories',
    id: 'key-stories',
    title: 'More knowledge in less time',
    subTitle: 'Curated content grouped, ranked, and condensed by AI in real-time to reduce reading and improve your investment decision-making.',
    link: 'https://www.cityfalcon.ai/features/summarisation',
    backgroundImage: leftWave,
    backgroundImageTab: rightWave,
    backgroundImageMob: leftWave,
    contentImage: keyStories,
    contentImageMob: keyStoriesMob,
    class: 'key_stories',
    styles: {
      marginTop: '93px',
      marginRight: '71px',
      width: '367px',
      height: '373px',
      bgWidth: '1060px',
      bgHeight: '237px',
    },
    tabStyles: {
      marginTop: '60px',
      marginRight: '39px',
      width: '220px',
      height: '224px',
      bgWidth: '840px',
      bgHeight: '190px',
    },
    mobStyles: {
      marginTop: '-5px',
      marginRight: '-100px',
      width: '162px',
      height: '141px',
      bgWidth: '727px',
      bgHeight: '149px',
    },
    bigScreenStyles: {
      marginTop: '214px',
      marginRight: '239px',
      width: '916px',
      height: '931px',
      bgWidth: '2649px',
      bgHeight: '592px',
    },
  },
  {
    tabName: 'All-in-one Tool',
    id: 'all-in-one-tool',
    title: 'The Only Due Diligence App You Need',
    subTitle: '300k Topics, Extensive Data, Valuable Analytics.',
    link: 'https://www.cityfalcon.ai/features',
    backgroundImage: rightWave,
    backgroundImageTab: leftWave,
    backgroundImageMob: leftWave,
    contentImage: allTool,
    contentImageMob: allToolMob,
    class: 'all_tool',
    styles: {
      marginTop: '30px',
      marginRight: '78px',
      width: '480px',
      height: '431px',
      bgWidth: '1060px',
      bgHeight: '237px',
    },
    tabStyles: {
      marginTop: '18px',
      marginRight: '49px',
      width: '303px',
      height: '271px',
      bgWidth: '840px',
      bgHeight: '190px',
    },
    mobStyles: {
      marginTop: '17px',
      marginRight: '10px',
      width: '163px',
      height: '146px',
      bgWidth: '727px',
      bgHeight: '149px',
    },
    bigScreenStyles: {
      marginTop: '60px',
      marginRight: '156px',
      width: '960px',
      height: '862px',
      bgWidth: '2649px',
      bgHeight: '592px',
    },
  },
  {
    tabName: 'Trending Content',
    id: 'trending-content',
    title: 'Never Miss a Breaking Story Again',
    subTitle: 'We track thousands of sources to bring you stories in real-time and identify trends across the globe.',
    link: 'https://www.cityfalcon.ai/news',
    backgroundImage: leftWave,
    backgroundImageTab: rightWave,
    backgroundImageMob: leftWave,
    contentImage: trendingContent,
    contentImageMob: trendingContentMob,
    class: 'trending_content',
    styles: {
      marginTop: '120px',
      marginRight: '30px',
      width: '500px',
      height: '282px',
      bgWidth: '1060px',
      bgHeight: '237px',
    },
    tabStyles: {
      marginTop: '75px',
      marginRight: '19px',
      width: '315px',
      height: '177px',
      bgWidth: '840px',
      bgHeight: '190px',
    },
    mobStyles: {
      marginTop: '12px',
      marginRight: '11px',
      width: '209px',
      height: '125px',
      bgWidth: '727px',
      bgHeight: '149px',
    },
    bigScreenStyles: {
      marginTop: '238px',
      marginRight: '60px',
      width: '1000px',
      height: '564px',
      bgWidth: '2649px',
      bgHeight: '592px',
    },
  },
  {
    tabName: 'International Stories',
    id: 'international-stories',
    title: 'Language is No Longer a Barrier',
    subTitle: 'Content in 50+ languages plus translation available.',
    link: 'https://www.cityfalcon.ai/features/language-coverage',
    backgroundImage: rightWave,
    backgroundImageTab: leftWave,
    backgroundImageMob: leftWave,
    contentImage: internationalStories,
    contentImageMob: internationalStoriesMob,
    class: 'international_stories',
    styles: {
      marginTop: '167px',
      marginRight: '30px',
      width: '547px',
      height: '215px',
      bgWidth: '1060px',
      bgHeight: '237px',
    },
    tabStyles: {
      marginTop: '71px',
      marginRight: '25px',
      width: '359px',
      height: '196px',
      bgWidth: '840px',
      bgHeight: '190px',
    },
    mobStyles: {
      marginTop: '32px',
      marginRight: '11px',
      width: '252px',
      height: '138px',
      bgWidth: '727px',
      bgHeight: '149px',
    },
    bigScreenStyles: {
      marginTop: '425px',
      marginRight: '129px',
      width: '1366px',
      height: '538px',
      bgWidth: '2649px',
      bgHeight: '592px',
    },
  },
];
